import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  makeStyles,
  FormGroup,
  DialogContentText,
  Typography,
  MenuItem,
  Select,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { GraphQLErrorDisplay } from '../../GraphQLErrorDisplay';
import {
  AdminUpdatePromoCodeMutation,
  AdminUpdatePromoCodeMutationVariables,
  PromoCodeStatus,
} from '../../../generated/graphql';

const useStyles = makeStyles(theme => ({
  productSelect: {
    marginBottom: theme.spacing(3),
    minWidth: 250,
  },
  confirmInput: {
    marginBottom: theme.spacing(1),
    minWidth: 500,
  },
}));

const adminUpdatePromoCode = gql`
  mutation adminUpdatePromoCode($input: AdminUpdatePromoCodeInput!) {
    adminUpdatePromoCode(input: $input) {
      promoCode {
        id
        status
      }
    }
  }
`;

export interface UpdatePromoCodeDialogProps {
  open: boolean;
  id: string;
  currentStatus: PromoCodeStatus;
  onCloseClick: () => void;
}

export const UpdatePromoCodeDialog: FC<UpdatePromoCodeDialogProps> = ({
  open,
  id,
  currentStatus,
  onCloseClick,
}) => {
  const styles = useStyles();
  const [newStatus, setNewStatus] = React.useState<PromoCodeStatus>(
    currentStatus,
  );

  const [updatePromoCode, { data, error, loading }] = useMutation<
    AdminUpdatePromoCodeMutation,
    AdminUpdatePromoCodeMutationVariables
  >(adminUpdatePromoCode);

  const handleConfirm = () => {
    if (newStatus && newStatus !== currentStatus) {
      updatePromoCode({ variables: { input: { id, status: newStatus } } });
    }
  };

  const handleSetNewStatus = (evt: any) => {
    const choice = evt.target.value as PromoCodeStatus;
    if (!choice || !Object.values(PromoCodeStatus).includes(choice)) {
      throw new Error(`Invalid promo code status choice: ${choice}`);
    }
    setNewStatus(choice);
  };

  const submitDisabled = loading || newStatus === currentStatus;

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Update Promo Code Status
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Changing the status of a promo code only affects whether the code
            can be redeemed or not. For example, setting the status to{' '}
            <i>refunded</i> will not issue a refund for the user. Any promo code
            with a status different from <i>active</i> can not be redeemed.
          </DialogContentText>
          <DialogContentText>Current status: {currentStatus}</DialogContentText>
          <FormGroup row={false}>
            <FormControl className={styles.productSelect}>
              <Select value={newStatus} onChange={handleSetNewStatus}>
                {Object.entries(PromoCodeStatus).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
          {data && !error && (
            <Typography variant="h6" component="h2">
              Successfully updated status
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseClick}>Close</Button>
          <Button onClick={handleConfirm} disabled={submitDisabled}>
            Set new status
          </Button>
        </DialogActions>
      </Dialog>
      <GraphQLErrorDisplay error={error} />
    </div>
  );
};
